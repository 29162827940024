import AccessComponent from 'components/access/acccess';
import React from 'react';
import GoogleButton from 'react-google-button';

function AccountComponent() {
    return (
        <div>
            <AccessComponent/>
        </div>
    );
}

export default AccountComponent;
